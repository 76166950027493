import { notifications as p } from "@mantine/notifications";
import { IconAlertCircleFilled as g, IconCircleCheckFilled as h, IconCircleXFilled as E, IconInfoCircleFilled as b } from "@tabler/icons-react";
import m from "react";
import { t as i } from "./vanilla-BVQNXG0s.mjs";
import { T as v } from "./Text-D75zcpkn.mjs";
import { z as l } from "zod";
import { t as f } from "i18next";
import "@mantine/vanilla-extract";
import "@mantine/core";
import "clsx";
class $ {
  constructor(e) {
    this.fetcher = e;
  }
  async get(e, r, a) {
    let o = e;
    if (r) {
      const s = new URLSearchParams();
      Object.keys(r).forEach((d) => {
        const c = r[d];
        Array.isArray(c) ? c.forEach((u) => {
          u != null && s.append(d, u);
        }) : c != null && s.append(d, c);
      }), o = `${e}?${s.toString()}`;
    }
    return this.fetcher.request(o, "GET", null, a);
  }
  async post(e, r, a, o, s) {
    return this.fetcher.request(e, "POST", r, a, o, s);
  }
  async update(e, r, a, o) {
    return this.fetcher.request(e, "PUT", r, a, o);
  }
  async delete(e, r, a, o) {
    return this.fetcher.request(e, "DELETE", null, a, o);
  }
  async patch(e, r, a, o) {
    return this.fetcher.request(e, "PATCH", r, a, o);
  }
}
class j {
}
var F = { root: "_1u5mhob0" };
const C = {
  warning: i.colors.warning[1],
  info: i.colors.positive[1],
  error: i.colors.negative[1],
  neutral: i.colors.neutral[2]
}, w = {
  warning: /* @__PURE__ */ m.createElement(g, null),
  info: /* @__PURE__ */ m.createElement(h, null),
  error: /* @__PURE__ */ m.createElement(E, null),
  neutral: /* @__PURE__ */ m.createElement(
    b,
    {
      color: "black",
      stroke: "black",
      style: { color: "black", stroke: "black", fill: "black" }
    }
  )
}, B = (t, { message: e, duration: r }) => {
  p.show({
    withCloseButton: !1,
    h: "46px",
    autoClose: r ?? 3e3,
    message: /* @__PURE__ */ m.createElement(v, { c: t === "neutral" ? "black" : "neutral.0", size: "md" }, e),
    color: "transparent",
    icon: w[t],
    loading: !1,
    classNames: { root: F.root },
    styles: {
      root: { backgroundColor: C[t], width: "fit-content" },
      description: { color: i.colors.neutral[0] },
      icon: {
        marginLeft: 0,
        marginRight: i.spacing.sm,
        width: "20px",
        height: "20px"
      }
    }
  });
}, n = f("common.validation.requiredField"), k = f("common.validation.invalidValue"), V = f("common.validation.invalidEmail"), q = l.string({
  errorMap: (t, e) => e.data === null ? { message: n } : { message: e.defaultError }
}).min(1, { message: n }).max(100), T = l.string({ required_error: n }).email({ message: V }).min(1, { message: n }).max(100), y = l.number({
  errorMap: (t, e) => !e.data || e.data === "" ? { message: n } : { message: e.defaultError }
}).nonnegative(), I = l.coerce.number({
  errorMap: (t, e) => !e.data || e.data === "" ? { message: n } : { message: e.defaultError }
}).nonnegative(), x = l.date({ required_error: n }).min(new Date(1850, 1, 1)).max(/* @__PURE__ */ new Date()), A = (t) => l.string().refine((e) => t.includes(e), { message: k }), G = {
  textFieldValidator: q,
  emailFieldValidator: T,
  numberFieldValidator: y,
  numberCoerceFieldValidator: I,
  dateFieldValidator: x,
  enumFieldValidator: A
};
export {
  j as Fetcher,
  $ as HttpClient,
  B as toast,
  G as validators
};
